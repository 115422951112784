import { defineAsyncComponent } from "vue"

/*异步获取模板 */
const loadComponent=(route,name)=>{
    return defineAsyncComponent(() =>
        import('/src/components/home/report/reportModel/'+(route?(route+'/'):"")+name+'/'+name+'.vue')
    )
}

/***纪录所有报表 模板 */
export default {
    /**报表模板 */
    components:{},
    /**模板名称 @param name 不区分大小写和- _符号*/
    getComponent(name){
        name=name?.toLocaleLowerCase().replace(/[-_]/g,'');
        
        let component=({
            /**交班表 */
            "shifthandover":{
                tagName:"shift-handover-report",
                name:'shiftHandoverReport'
            },
            /**营业日报 */
            "businessdailyreport":{
                tagName:"business-daily-report",
                name:'businessDailyReport'
            },
            /**异常操作统计表 */
            "reportoperation":{
                tagName:"report-operation-report",
                name:'reportOperationReport'
            },
            /**单据稽核报表 */
            "reportbillcheck":{
                tagName:"bill-check-report",
                name:'billCheckReport'
            },
            /**付款明细报表 */
            "paymentdetailsreport":{
                tagName:"payment-details-report",
                name:'paymentDetailsReport'
            },
            //单据稽核报表 订单详情
            "billcheckdetailreport":{
                tagName:"bill-check-detail-report",
                name:"billCheckDetailReport"
            },
            //菜品销售统计表
            "billsalereport":{
                tagName:"bill-sale-report",
                name:"billSaleReport"
            },
            //预订排菜报表
            "reportpreinfo":{
                tagName:"report-pre-info",
                name:"reportPreInfo"
            },
            //每日账单毛利率表
            "reportbillcost":{
                tagName:"report-bill-cost",
                name:"reportBillCost"
            },
            //菜品毛利详情表
            "reportbillcostdetail":{
                tagName:"report-bill-cost-detail",
                name:"reportBillCostDetail",
                route:"reportBillCost"
            },
            //菜品原材料分析表
            "rawmaterialanalysisreport":{
                tagName:"raw-material-analysis-report",
                name:"rawMaterialAnalysisReport",
                route:"reportBillCost"
            },
            //厨师业绩统计表
            "reportkitnameperf":{
                tagName:"report-kit-name-perf",
                name:"reportKitNamePerf"
            },
            //厨师账单统计表
            "reportkitnamebill":{
                tagName:"report-kit-name-bill",
                name:"reportKitNameBill",
                route:"reportKitNamePerf"
            },
            //厨师账单毛利详情表
            "reportkitnamebillprofitdetails":{
                tagName:"report-kit-name-bill-profit-details",
                name:"reportKitNameBillProfitDetails",
                route:"reportKitNamePerf"
            },
            //点单员业绩统计表
            "reportorderperf":{
                tagName:"report-order-perf",
                name:"reportOrderPerf"
            },
            //点单员账单统计表
            "reportorderbill":{
                tagName:"report-order-bill",
                name:"reportOrderBill",
                route:"reportOrderPerf"
            },
            //点单员账单毛利详情表
            "reportorderbillprofitdetails":{
                tagName:"report-order-bill-profit-details",
                name:"reportOrderBillProfitDetails",
                route:"reportOrderPerf"
            },
            //点单员业绩统计表
            "reportwaiternameperf":{
                tagName:"report-waiter-name-perf",
                name:"reportWaiterNamePerf"
            },
            //服务员账单统计表
            "reportwaiternamebill":{
                tagName:"report-waiter-name-bill",
                name:"reportWaiterNameBill",
                route:"reportWaiterNamePerf"
            },
            //服务员账单毛利详情表
            "reportwaiterbillprofitdetails":{
                tagName:"report-waiter-bill-profit-details",
                name:"reportWaiterBillProfitDetails",
                route:"reportWaiterNamePerf"
            },
            //菜品实时状态监控表
            "reporteatservetime":{
                tagName:"report-eatservetime",
                name:"reportEatservetime"
            },
            //退菜时间分析表A3
            "backfoodtimereport":{
                tagName:"back-food-time-report",
                name:"backFoodTimeReport",
                route:"reportEatservetime"
            },
            //上菜顺序速度异常监控表
            "reporteatserve":{
                tagName:"report-eatserve",
                name:"reportEatserve"
            },
            //按 (时间、上菜顺序 ) 分析 （正常桌数、超时桌数、异常桌数）
            "reporteatanalysis":{
                tagName:"report-eat-analysis",
                name:"reportEatAnalysis",
                route:"reportEatserve"
            },
            //上菜顺序速度明细表
            "reporteatservedetail":{
                tagName:"report-eatserve-detail",
                name:"reportEatserveDetail",
                route:"reportEatserve"
            },
            //催菜分析表
            "reporteaturgefood":{
                tagName:"report-eat-urge-food",
                name:"reportEatUrgeFood",
                route:"reportEatserve"
            },
            //上菜顺序速度指标分析表
            "reporteatservetarget":{
                tagName:"report-eat-serve-target",
                name:"reportEatServeTarget",
            },
            //超时菜品明细表
            "reporteatovertimefood":{
                tagName:"report-eat-over-time-food",
                name:"reportEatOverTimeFood",
                route:"reportEatServeTarget"
            },
            //点单流水日志表
            "reportlog":{
                tagName:"report-log",
                name:"reportLog",
            },
            //销售员业绩报表
            "reportsalecommission":{
                tagName:"report-sale-commission",
                name:"reportSaleCommission",
            },
            //销售员账单统计表
            "reportsalenamebill":{
                tagName:"report-sale-name-bill",
                name:"reportSaleNameBill",
                route:"reportSaleCommission"
            },
            //券核销统计表
            "reporttkreceinfo":{
                tagName:"report-tk-rece-info",
                name:"reportTkReceInfo"
            },
            //按桌号计时统计表
            "reporteatdesktime":{
                tagName:"report-eat-desktime",
                name:"reportEatDesktime"
            },
            //按桌号计时 具体账单表
            "eatdesktimeorder":{
                tagName:"eat-desktime-order",
                name:"eatDesktimeOrder",
                route:"reportEatDesktime"
            },
            //按桌号计时账单明细表
            "reporteatdeskorderdetail":{
                tagName:"report-eat-desk-order-detail",
                name:"reportEatDeskOrderDetail",
                route:"reportEatDesktime"
            }
        })[name];
        if(component){
            if(!this.components[component.name]){
                this.components[component.name]=loadComponent(component.route,component.name);
            }
            return component.tagName;
        }
        return;
    }
}